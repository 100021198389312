@font-face {
	font-family: 'BrandFontLight';
	src: url("~@/assets/fonts/Cabin-Regular.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'BrandFontRegular';
	src: url("~@/assets/fonts/Cabin-Regular.ttf");
	font-style: normal;
}

@font-face {
	font-family: "BrandFontMedium";
	src: url("~@/assets/fonts/Cabin-Medium.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'BrandFontBold';
	src: url("~@/assets/fonts/Cabin-SemiBold.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'BrandFontBlack';
	src: url("~@/assets/fonts/Cabin-Bold.ttf");
	font-style: normal;
}

@font-face {
	font-family: 'BrandFontItalic';
	src: url("~@/assets/fonts/Cabin-Italic.ttf");
	font-style: normal;
}

:root {
	--app-font-regular: BrandFontRegular;
	--app-font-bold: BrandFontBold;
	--app-font-italic: BrandFontItalic;
}