
					@import "~@/assets/styles/_variables.scss";
					@import "~@/assets/styles/mixins/global.scss";
				

	.page-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		min-height: calc(100vh - #{$header-total-height});
		padding-bottom: 24px;

		.logo-constraints {
			max-width: 512px;
			flex-grow: 0;
		}
		.text {
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: center;

			&.header {
				font-size: 32px;
				font-weight: bold;
				line-height: 1.05;
				padding-top: 16px;
			}
			&.subheader {
				font-size: 22px;
				font-weight: bold;
				line-height: 1.05;
			}
		}
		.appt-booked-text {
			margin: 24px 0 8px 0;
		}

		.line-position-indicator {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			min-height: 130px;

			.line-position {
				font-weight: bold;
				font-size: 99px;
				margin: 0 24px 0 28px;
			}

			.line-position-text {
				@extend .line-position;
				font-size: 32px;
			}
		}

		.button-wrapper {
			margin: 24px 0 0 0;
			width: 100%;
			max-width: 344px;
		}
	}
